export const setUrlStatus = (url, status)=>{
    return {
        type: 'UPDATE_STATUS',
        url,
        status
    }
}

export const setDataFromServer = (url, body)=>{
    return {
        type: 'FETCH_DATA',
        url,
        body,
    }
}

export const  fetchData = (urlSuffix, body = {}, memoUrl = '', ignoreCache = false)=> {
    return (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    return fetch(`https://api.theboattrip.eu${urlSuffix}`, {
        method: "POST",
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            dispatch(setDataFromServer(cachedUrlName, data));
            dispatch(setUrlStatus(cachedUrlName, 'done'))
        }).catch((status)=>{
            dispatch(setUrlStatus(cachedUrlName, 'error'))
        });
  }
}