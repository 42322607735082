export const getOfferCachedUrlName = (offerId, name)=>`/v1/offers/details/${offerId}${name}`;
export const getDestinationCachedUrlName = (countryName) =>`/v1/data/page/${countryName}`;


export const offerListCacheUrl = 'offerListUrl';
export const offerFilterUrlCacheUrl = 'offerFilterUrl';
export const dataPageCacheUrl = 'dataPageUrl';
export const cruisesInPolandUrl = 'cruisesInPolandUrl';
export const offerListFilterByCountry6 = 'offerListFilterByCountry6';
export const howItWorksPageUrl = 'howItWorks';
export const homePageCachedUrl = 'homePage';
export const contactPageCachedUrl = 'contactPageCachedUrl';
export const discountCachedUrl = 'discountCachedUrl';
export const faqCachedUrl = 'faqCachedUrl';
export const aboutUsCachedUrl = 'aboutUsCachedUrl';
export const covidCachedUrl = 'covidCachedUrl';
export const ourTripsCachedUrl = 'ourTripsCachedUrl';
export const ourTripsFilterCachedUrl = 'ourTripsFilterCachedUrl';
export const sailingTrainingCachedUrl = 'sailingTrainingCachedUrl';
export const workCachedUrl = 'workCachedUrl';
export const companyTripCachedUrl = 'companyTripCachedUrl';
export const panelMenuChachedUrl = 'panelMenuChachedUrl';